<template>
	<div>
		<div class="md:flex md:items-center md:justify-between mb-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Providers
				</h2>
			</div>
			<div class="mt-4 flex gap-x-5 md:mt-0 md:ml-4">
				<a
					:href="apiUrl + '/providers/download'"
					target="_blank"
					class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none hover:cursor-pointer"
				>
					<DownloadIcon class="h-5 w-5 text-white mr-2" aria-hidden="true" />
					Download Excel
				</a>

				<RefreshTableButton @refreshTable="refreshTable" />
			</div>
		</div>

		<div class="flex flex-col">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<ag-grid-vue
						style="height: 725px"
						class="ag-theme-alpine"
						:grid-options="gridOptions"
						:column-defs="columnDefs"
						:default-col-def="defaultColDef"
						:row-data="rowData.value"
						:modules="modules"
						:pagination="true"
						:pagination-page-size="50"
						:enable-cell-text-selection="true"
						:ensure-dom-order="true"
					></ag-grid-vue>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { inject, ref, reactive, onMounted, getCurrentInstance } from 'vue'
import { useToast } from 'vue-toastification'
import getEnv from '@/extensions/env'

import { AgGridVue } from '@ag-grid-community/vue3'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import TableActions from '@/components/TableActions.vue'

import { DownloadIcon } from '@heroicons/vue/outline'

export default {
	name: 'Providers',
	components: {
		AgGridVue,
		DownloadIcon,
	},
	setup() {
		const root = getCurrentInstance()
		const api = inject('api')
		const toast = useToast()

		const apiUrl = getEnv('VUE_APP_API_URL')

		const rowData = reactive({})
		const gridApi = reactive({})
		const gridOptions = reactive({})
		const columnDefs = reactive([
			{ field: 'name', flex: 1 },
			{ field: 'department.name', flex: 1 },
			{ headerName: 'Last Updated', field: 'draft_page.updated_at' },
			{ headerName: 'Publish Date', field: 'publish_date' },
			{
				headerName: 'Active',
				field: 'active',
				width: 100,
				valueFormatter: booleanFormatter,
				cellRenderer: params => {
					return !params.value
						? '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">No</span>'
						: '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Yes</span>'
				},
			},
			{
				headerName: 'Unpublished Changes',
				field: 'unpublished_changes',
				width: 190,
				valueFormatter: booleanFormatter,
				cellRenderer: params => {
					return !params.value
						? ''
						: '<span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">Yes</span>'
				},
			},
			{
				headerName: 'Actions',
				width: 300,
				cellRendererFramework: TableActions,
				cellRendererParams: {
					editRoute: 'md-staff-providers-id',
					showEdit: true,
					newTab: true,
				},
			},
		])
		const defaultColDef = reactive({
			resizable: true,
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
		})
		const modules = ref([ClientSideRowModelModule])

		const booleanFormatter = params => {
			return params.value === 0 ? 'No' : 'Yes'
		}

		const fetchProviders = (reload = false) => {
			if (reload) {
				gridApi.value.showLoadingOverlay()
			}
			root.ctx.loaderShow()

			api.providers
				.all()
				.then(data => {
					rowData.value = data
				})
				.catch(error => {
					toast.error('Error fetching providers:\n' + error.response.data.message)
				})
				.then(() => {
					root.ctx.loaderHide()
					/* if (reload) {
						gridApi.hideOverlay()
					} */
				})
		}

		const refreshTable = () => {
			fetchProviders(true)
		}

		onMounted(() => {
			gridApi.value = gridOptions.api
			fetchProviders()
		})

		return {
			api,
			rowData,
			gridApi,
			gridOptions,
			columnDefs,
			defaultColDef,
			modules,
			booleanFormatter,
			fetchProviders,
			refreshTable,
			apiUrl,
		}
	},
}
</script>
